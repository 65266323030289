import { Action } from '@ngrx/store';
import { IUser } from '../../interfaces/user.interface';

export enum EUserActions {
  UpdateUser = '[User] Update user',
  RemoveUser = '[User] Remove user',
}

export class RemoveUser implements Action {
  public readonly type = EUserActions.RemoveUser;
  constructor() {}
}

export class UpdateUser implements Action {
  public readonly type = EUserActions.UpdateUser;
  constructor(public user: IUser) {}
}

export type UserActions = RemoveUser | UpdateUser;
